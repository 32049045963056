.gray-bg-1 {
	background-color: var(--px-gray-1);
}

.gray-bg-2 {
	background-color: var(--px-gray-2);
}

.gray-bg-3 {
	background-color: var(--px-gray-3);
}

.gray-bg-4 {
	background-color: var(--px-gray-4);
}

.gray-bg-5 {
	background-color: var(--px-gray-5);
}



.theme-bg {
	background-color: var(--px-theme);
}

.theme-light-bg {
	background-color: var(--px-theme-light);
}

.theme-dark-bg {
	background-color: var(--px-theme-dark);
}