.footer {
	position: relative;
	background-color: var(--px-bg);
	border-top: 2px solid var(--px-gray-2);
	.footer-info {
		text-align: center;
		margin-top: -50px;
		.footer-avatar {
			width: 100px;
			height: 100px;
			display: flex;
			margin: 0 auto;
			border-radius: 50%;
			border: 8px solid var(--px-gray-1);
			padding: 5px;
			background-color: var(--px-gray-3);
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
			}
		}
		h6 {
			margin: 15px 0 0;
		}
	}

	.social-link {
		justify-content: center;
		padding: 20px 0;
		li {
			margin: 0 5px;
		}
		a {
			width: 40px;
			height: 40px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		    background: var(--px-theme);
		    color: var(--px-theme-text);
		    border-radius: 10px;
		    transition: ease all 0.35s;
		    border: 2px solid var(--px-bg);
		    &:hover {
		    	background: var(--px-theme-text);
		    	color: var(--px-theme);
		    }
		}
	}
	.copyright {
		font-size: 12px;
		margin: 0 0 40px;
		text-align: center;
	}
}