@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

$px-font:			'Rubik', sans-serif !default;


// Theme Variable

$theme:						#ffdad9;		// --mio-theme-color-secondary-container
$theme-text:				#2d1516;		//--mio-theme-color-on-secondary-container


$bg:						#fcfcfc;		//--mio-theme-color-background
$text:						#534343;		//--mio-theme-color-on-surface-variant



$gray-1:					#f5f0f0;		//--mio-theme-color-surface-1
$gray-2:					#f3eaeb;		//--mio-theme-color-surface-2
$gray-3:					#f0e5e5;		//--mio-theme-color-surface-3
$gray-4:					#efe3e4;		//--mio-theme-color-surface-4
$gray-5:					#eddfe0;		//--mio-theme-color-surface-5


$text-heading:				#211a1a;		//--mio-theme-color-neutral-10
$border:					#f4dedd;		//--mio-theme-color-on-surface-variant // --mio-theme-color-surface-variant



// Dark Theme Variable

$dark-theme:					#5c3f3f;		// --mio-theme-color-secondary-container
$dark-theme-text:				#ffdad9;		//--mio-theme-color-on-secondary-container


$dark-bg:						#211a1a;		//--mio-theme-color-background
$dark-text:						#d7c2c1;		//--mio-theme-color-on-surface-variant

$dark-text-heading:				#ede0df;		//--mio-theme-color-neutral-10

$dark-gray-1:					#2f2525;		//--mio-theme-color-surface-1
$dark-gray-2:					#362929;		//--mio-theme-color-surface-2
$dark-gray-3:					#3c2e2e;		//--mio-theme-color-surface-3
$dark-gray-4:					#3e2f2f;		//--mio-theme-color-surface-4
$dark-gray-5:					#433232;		//--mio-theme-color-surface-5


$dark-border:					#534343;		//--mio-theme-color-on-surface-variant



$border-radius-sm:	4px;
$border-radius:		8px;
$border-radius-lg:	12px;
$border-radius-xl:	16px;


// Breakpoints
$px-media-xsm:		567px !default;
$px-media-sm:		767px !default;
$px-media-md:		991px !default;
$px-media-lg:		1200px !default;
